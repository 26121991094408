import React, { Component } from 'react';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar/index';
import { UploadOutlined, ExclamationCircleOutlined, SyncOutlined, WarningOutlined, EditOutlined, InfoCircleOutlined, CheckCircleOutlined, DeleteOutlined, ImportOutlined, HistoryOutlined } from '@ant-design/icons';
import { Table, Modal, Tag, Divider, Alert, Image, Button, TreeSelect, Upload, Tooltip, Drawer, Radio, Spin, Col, Row, Input, Select, Card, Tabs } from 'antd';
import { connect } from 'react-redux';
import { Form } from "@ant-design/compatible";
import { Link } from 'react-router-dom';
import TextTruncate from 'react-text-truncate';
import qs from 'qs';
import moment from 'moment';
import _ from 'lodash';
import { cleanObject, makeTree, getBase64, stripHtml } from '../../utils/helpers';
import BaseSelect from '../../components/Elements/BaseSelect';
import { checkPermission } from '../../utils/permission';
import ImporterViewForm from './ImporterViewForm';
import DescriptionSelect from './DescriptionSelect';
import ReactHtmlParser from 'react-html-parser';
import { NotificationManager } from 'react-notifications'
import { isMobile } from 'react-device-detect';
import DynamicTable from '../../components/DynamicTable';

// actions
import { getAllQueue, removeQueue, getOne, importNow, setThumbnail, removeQueueImage, updateRecordProduct, manyUpdate, resetImportStatus } from '../../redux/actions/ProductsImportAction';
import { getAllCategories } from '../../redux/actions/ProductsCategoriesAction';
import { getProductTypeByActive } from '../../redux/actions/ProductTypeActions';
import { getAllTagsInProductForm } from '../../redux/actions/ProductsTagsAction';
import { getStoreByField } from '../../redux/actions/StoreActions';

const { confirm } = Modal;
const { Option } = Select;
const { TabPane } = Tabs;

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/products-import',
        breadcrumbName: 'Tiến trình import',
    }
]

class ProcessList extends Component {

    constructor() {
        super();
        this.state = {
            isLoading: false,
            selectedRowKeys: [],
            images: [],
            importResponseMessage: null,
            importResponseType: null,
            loadingUpload: false,
            imageList: [],
            currentItem: null,
            openView: false,
            isOpenDescriptionSelect: false,
            isOpenEdit: false,
            productList: null,
            productImagesList: [],
            LoadingOpenEdit: false,
            idChecked: null,
            isloading_delete: false,
            currentQueueId: null,
            tags: [],
            tagsSelected: [],
            loadingCategory: false,
            loadingTags: false,
            isloadingUpdateItem: false,
            categoryName: null,
            isOpenManyUpdate: false,
            currentStoreId: null
        }
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({
            isLoading: true
        });
        this.props.getAllQueue(query).then((res) => {
            this.setState({
                isLoading: false
            })
        });
        this.props.getStoreByField({ platform: ['pod', 'shield'] })
        this.props.getAllCategories();
        this.props.getProductTypeByActive();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.items !== this.props.items) {
            this.props.getAllCategories({ store_id: this.props.items.store_id });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.items.store_id) {
            this.setState({ currentStoreId: nextProps.items.store_id })
        }
        if (nextProps.location.search !== this.props.location.search) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getAllQueue(query).then(() => {
                this.setState({ isLoading: false });
            })
        }
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys })
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'asc' : 'desc'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: '/products-import',
            search: qs.stringify(query),
        })
    }

    reloadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({
            isLoading: true
        });
        this.props.getAllQueue(query).then(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }
        query = cleanObject(query);
        this.props.history.push({
            pathname: '/products-import',
            search: qs.stringify(query)
        })
    }

    handleCancel = () => {
        this.props.form.resetFields();
        this.setState({
            isOpenViewForm: false,
            selectedRowKeys: [],
            images: [],
            imageList: [],
            countImages: [],
            categoryName: null,
            isOpenManyUpdate: false,
            isloadingUpdateItem: false
        })
    }

    onSetMessage = (message, type) => {
        this.setState({ importResponseMessage: message, importResponseType: type })
    }

    onRemove() {
        this.setState({ isloading_delete: true })
        this.props.removeQueue(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: [],
                isloading_delete: false
            });
            setTimeout(() => {
                Modal.destroyAll();
            }, 800);
        })
    }

    removeOne(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá bản ghi này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeQueue(id).then(() => {
                const selectedRowKeys = [...this.state.selectedRowKeys];
                const newArray = selectedRowKeys.filter(item => item != id)
                this.setState({ selectedRowKeys: newArray });
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
            }),
            onCancel() { },
        })
    }

    async onBeforeUpload(file) {
        this.setState({ loadingUpload: true })
        var path = file.webkitRelativePath;
        let pathArray = path.split('/');
        let fName;
        if (pathArray.length == 2) {
            fName = file.name.replace(/\.[^/.]+$/, "");
        } else {
            fName = pathArray.slice(-2)[0];
        }
        //console.log(file.name.length,": ", file.name)
        let data = await getBase64(file);
        const objectImg = {
            folderName: fName,
            description: "<p>" + fName + "</p>\n",
            images: {
                data: data,
            }
        }
        this.setState({ imageList: [...this.state.imageList, objectImg] })
        const out = this.state.imageList.reduce((acc, c, index) => {
            // Grab the folder_name as the key
            const { folderName: key, images } = c;
            // If the key doesn't exist on the accumulator
            // create a new object with a file_name array
            acc[key] = acc[key] || { folderName: key, images: [] };
            // Push the filename into the new object array
            acc[key].id = index;
            acc[key].images.push(images);
            acc[key].description = c.description;
            const [firstItem] = acc[key].images; // Lấy item đầu tiên
            acc[key].thumbnail = firstItem;
            acc[key].gallery = [...acc[key].images];
            acc[key].deleteFirstItem = acc[key].gallery.shift();// Xóa item đầu tiên
            // Return the accumulator
            return acc;
        }, {});

        // Grab the object values (an array) from the
        // returned object
        //console.log(Object.values(out));
        this.setState({
            images: Object.values(out),
            isOpenViewForm: true,
            loadingUpload: false
        });
    }

    onView(id) {
        this.props.getOne(id).then(res => {
            this.setState({ openView: true, currentItem: res });
        })
    }

    async onImport() {
        try {
            if (this.state.selectedRowKeys) {
                confirm({
                    title: 'Xác nhận',
                    icon: <ExclamationCircleOutlined />,
                    content: 'Chắc chắn import sản phẩm?',
                    okText: 'OK',
                    cancelText: 'Huỷ',
                    onOk: async () => {
                        this.props.importNow({ ids: this.state.selectedRowKeys });
                        this.handleCancel()
                        this.reloadData()
                        NotificationManager.success("Sản phẩm đang được import lên store. Vui lòng kiểm tra trong danh sách sản phẩm.")
                    }
                })
            } else {
                NotificationManager.error("Không nhận được ids");
            }
        } catch (error) {
        }
    }

    async onImportOne(id) {
        try {
            if (id) {
                confirm({
                    title: 'Xác nhận',
                    icon: <ExclamationCircleOutlined />,
                    content: 'Chắc chắn import sản phẩm?',
                    okText: 'OK',
                    cancelText: 'Huỷ',
                    onOk: async () => {
                        this.props.importNow({ ids: [id] });
                        this.handleCancel()
                        this.reloadData()
                        NotificationManager.success("Sản phẩm đang được import lên store. Vui lòng kiểm tra trong danh sách sản phẩm.")
                    }
                })
            } else {
                NotificationManager.error("Không nhận được id");
            }
        } catch (error) {
        }
    }

    openEdit(queue_id) {
        this.setState({
            LoadingOpenEdit: true,
            currentQueueId: queue_id,
            selectedRowKeys: [...new Set(this.state.selectedRowKeys), queue_id],
            loadingCategory: true,
            loadingTags: true
        })
        this.props.getOne(queue_id).then(res => {
            //console.log(res)
            this.props.getAllCategories({ store_id: res.store_id }).then(res => {
                this.setState({ loadingCategory: false })
            })
            this.props.getAllTagsInProductForm({ store_id: res.store_id }).then(res => {
                this.setState({ tags: res.data.data, loadingTags: false })
            });
            this.setState({ productList: res, productImagesList: res.images, LoadingOpenEdit: false });
        })
        this.setState({ isOpenEdit: true, })
    }

    onDeleteImage(id, queue_id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá ảnh này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeQueueImage(id, queue_id).then(() => {
                const productImagesList = [...this.state.productImagesList];
                this.setState({ productImagesList: productImagesList.filter(item => item.id !== id) });
            }),
            onCancel() { },
        })
    }

    onChangeThumbnail(e) {
        //console.log('e: ', e)
        this.setState({ idChecked: e.target.value })
        this.props.setThumbnail({ queue_id: e.target.queue_id, id: e.target.value })
    }

    onUpdateItem(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isloadingUpdateItem: true });
                try {
                    const { tagsSelected, categoryName } = this.state;
                    var tags = tagsSelected;
                    if (tagsSelected.length <= 0) {
                        tags = this.state.productList.currentObjTags
                    }
                    const params = {
                        category_id: values.categories,
                        main_categories_id: values.main_categories_id,
                        category_name: categoryName != null ? categoryName : JSON.parse(this.state.productList.categories_name),
                        product_type_id: values.product_type_id,
                        title: values.title,
                        //tags: tags
                    }
                    delete values.categories;
                    //console.log('import: ', params)
                    this.props.updateRecordProduct(this.state.productList.id, params).then(res => {
                        //console.log("res: ", res)
                        this.setState({ isloadingUpdateItem: false });
                    }).catch(err => {
                        this.setState({ isloadingUpdateItem: false });
                    });
                } catch (error) {
                    this.setState({ isloadingUpdateItem: false });
                }
            }
        });
    }

    handleCategoryChange = (value, name) => {
        this.setState({
            categoryId: value,
            categoryName: name
        });
    };

    handleTagsChange = (id, e) => {
        var filtered = e.filter(function (el) {
            return el.value != null;
        });
        const result = filtered.map(item => {
            return {
                tagsId: item.value,
                tagsName: item.children
            }
        })
        this.setState({ tagsSelected: result });
    };

    openManyUpdate() {
        this.setState({ isOpenManyUpdate: true })
    }

    onManyUpdateItem(e) {
        e.preventDefault();
        if (this.props.categories == undefined) {
            NotificationManager.error('Vui lòng chọn site trước khi thực hiện');
            return;
        }
        this.props.form.validateFields(['product_type', 'main_categories', 'categories2'], async (err, values) => {
            if (!err) {

                this.setState({ isloadingUpdateItem: true });
                try {
                    const { categoryName, currentStoreId, selectedRowKeys } = this.state;
                    const params = {
                        store_id: currentStoreId,
                        category_id: values.categories2 ? values.categories2.filter(function (e) { return e }) : null,
                        main_categories_id: values.main_categories,
                        category_name: categoryName ? categoryName.filter(function (e) { return e }) : null,
                        product_type_id: values.product_type,
                        ids: selectedRowKeys
                    }
                    delete values.categories;
                    //console.log('import: ', params)
                    this.props.manyUpdate(params).then(res => {
                        //console.log("res: ", res)
                        this.reloadData();
                        this.handleCancel();
                    }).catch(err => {
                        this.setState({ isloadingUpdateItem: false });
                    });
                } catch (error) {
                    this.setState({ isloadingUpdateItem: false });
                }
            }
        });
    }

    async _resetImportStatus() {
        try {
            if (this.state.selectedRowKeys) {
                confirm({
                    title: 'Xác nhận',
                    icon: <ExclamationCircleOutlined />,
                    content: 'Chắc chắn thực hiện thao tác này?',
                    okText: 'OK',
                    cancelText: 'Huỷ',
                    onOk: async () => {
                        await this.props.resetImportStatus(this.state.selectedRowKeys);
                        this.setState({ selectedRowKeys: [] })
                        this.reloadData()
                    }
                })
            } else {
                NotificationManager.error("Không nhận được ids");
            }
        } catch (error) {
        }
    }

    onSearch() { }
    onScrollEnd() { }

    render() {
        var {
            isLoading,
            isOpenViewForm,
            loadingUpload,
            images,
            imageList,
            selectedRowKeys,
            importResponseMessage,
            importResponseType,
            isOpenDescriptionSelect,
            openView,
            currentItem,
            isOpenEdit,
            productList,
            productImagesList,
            LoadingOpenEdit,
            idChecked,
            isloading_delete,
            currentQueueId,
            tags,
            loadingCategory,
            loadingTags,
            isloadingUpdateItem,
            isOpenManyUpdate
        } = this.state;
        const { items, pagination, stores, categories, producttype, authUser, users } = this.props;
        var query = qs.parse(this.props.location.search.slice(1));
        var hasSelected = selectedRowKeys.length > 0;
        const catTree = makeTree(categories || [], 0);
        const { getFieldDecorator } = this.props.form;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        //console.log("items: ", items)
        var is_trademark = query?.is_trademark;

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: true,
                key: 'id',
                width: '50px'
            },
            {
                title: 'Actions',
                align: 'center',
                width: '120px',
                key: 'actions',
                render: (text, record) => {
                    return (
                        <>
                            <Tooltip placement="topLeft" title="Chỉnh sửa" color="#3cba81">
                                <EditOutlined style={{ color: '#1BAE70', cursor: 'pointer', fontSize: "1.2rem" }} onClick={() => this.openEdit(record.id)} />
                            </Tooltip>
                            {is_trademark == 0 && (
                                <>
                                    <Divider type='vertical' />
                                    <Tooltip placement="topLeft" title="Import" color="#3cba81">
                                        <ImportOutlined style={{ color: '#108ee9', cursor: 'pointer', fontSize: "1.1rem" }} onClick={() => this.onImportOne(record.id)} />
                                    </Tooltip>
                                </>
                            )}
                            <Divider type='vertical' />
                            {
                                checkPermission('products_import', 'remove_queue') ? (
                                    <Tooltip placement="topLeft" title="Xóa" color="#3cba81">
                                        <DeleteOutlined style={{ color: 'red', cursor: 'pointer', fontSize: "1.2rem" }} onClick={() => this.removeOne([record.id])} />
                                    </Tooltip>
                                ) : null
                            }
                        </>
                    )
                }
            },
            {
                title: 'Thumbnail',
                width: '90px',
                key: 'thumbnail',
                render: (text, record) => {
                    if (record.thumbnail) {
                        return (
                            <div style={{ wordWrap: 'break-word', wordBreak: 'break-word', position: 'relative' }}>
                                <Image
                                    width='100%'
                                    src={record.thumbnail}
                                />
                            </div>
                        )
                    }

                }
            },
            {
                title: 'Gallery',
                width: '150px',
                align: 'center',
                key: 'gallery',
                render: (text, record) => {
                    if (Array.isArray(record.gallery)) {
                        var width;
                        if (record.gallery.length <= 1) {
                            width = 75;
                        } else if (record.gallery.length <= 2) {
                            width = 60;
                        } else if (record.gallery.length <= 3) {
                            width = 45;
                        } else {
                            width = 35;
                        }
                        return (
                            <div>
                                <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                                    <Image.PreviewGroup>
                                        {
                                            record.gallery.map(item => {
                                                return (
                                                    <Image
                                                        width={width}
                                                        src={item?.images}
                                                    />
                                                )
                                            })
                                        }
                                    </Image.PreviewGroup>
                                </div>
                            </div>
                        )
                    }

                }
            },
            {
                title: 'Name',
                dataIndex: 'title',
                key: 'title',
                width: '300px',
                render: (text, record) => {
                    return (
                        <div onClick={() => this.onView(record.id)} style={{ cursor: 'pointer', wordBreak: "break-all" }}>
                            <Tooltip placement="topLeft" title="Click để xem chi tiết" color="#3cba81">
                                <TextTruncate line={3} truncateText="…" text={stripHtml(text)} />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Mô tả',
                dataIndex: 'description_status',
                sorter: true,
                key: 'description_status',
                width: '80px',
                align: 'center',
                render: (text, record) => {
                    var tag = "";
                    if (record.description_status == 1) {
                        tag = <Tooltip placement="topLeft" title="Đã tạo">
                            <CheckCircleOutlined style={{ fontSize: '1.3rem', color: '#0191b4' }} />
                        </Tooltip>
                    }
                    if (record.description_status == 2) {
                        tag = <Tooltip placement="topLeft" title="Chưa tạo">
                            <InfoCircleOutlined style={{ fontSize: '1.3rem', color: '#fe7a15' }} />
                        </Tooltip>
                    }
                    return (
                        <div>
                            <div>{tag}</div>
                        </div>
                    )
                }
            },
            {
                title: 'Trạng thái',
                dataIndex: 'import_status',
                sorter: true,
                align: 'center',
                key: 'import_status',
                width: '100px',
                render: (text, record) => {
                    var tag = "";
                    if (record.import_status == 1) {
                        tag = <Tooltip placement="topLeft" title="Chưa import">
                            <HistoryOutlined style={{ fontSize: '1.3rem', color: '#fe7a15' }} />
                        </Tooltip>
                    }
                    if (record.import_status == 2) {
                        tag = <Tooltip placement="topLeft" title="Đang import">
                            <SyncOutlined spin style={{ fontSize: '1.3rem', color: 'green' }} />
                        </Tooltip>
                    }
                    if (record.import_status == 3) {
                        tag = <Tooltip placement="topLeft" title="Import lỗi">
                            <WarningOutlined style={{ fontSize: '1.3rem', color: 'red' }} />
                        </Tooltip>
                    }
                    return (
                        <div>
                            <div>{tag}</div>
                        </div>
                    )
                }
            },
            {
                title: 'Product type',
                width: '120px',
                dataIndex: 'product_type_name',
                key: 'product_type_name'
            },
            {
                title: 'Categories',
                width: '150px',
                key: 'categories',
                render: (text, record) => {
                    return (
                        <ul className="custom-ul">
                            {
                                record.categories.map((item, index) => {
                                    return (
                                        <li key={index}>{item}</li>
                                    )
                                })
                            }
                        </ul>
                    )
                }
            },
            {
                title: 'Platfrom',
                width: '80px',
                sorter: true,
                dataIndex: 'platform',
                key: 'platform',
                render: (text, record) => {
                    if (record.platform == 'etsy') {
                        return (
                            <Tag style={{ textTransform: 'capitalize' }} color='#F1641E'>{record.platform}</Tag>
                        )
                    } else if (record.platform == 'redbubble') {
                        return (
                            <Tag style={{ textTransform: 'capitalize' }} color='#19124F'>{record.platform}</Tag>
                        )
                    } else {
                        return <Tag></Tag>
                    }
                }
            },
            {
                title: 'Type',
                width: '80px',
                key: 'type',
                render: (text, record) => {
                    if (record.type == 'trend') {
                        return (
                            <Tag style={{ textTransform: 'capitalize' }} color='green'>{record.type}</Tag>
                        )
                    } else {
                        return (
                            <Tag style={{ textTransform: 'capitalize' }}>{record.type}</Tag>
                        )
                    }
                }
            },
            {
                title: 'Tags',
                width: '150px',
                key: 'tags',
                render: (text, record) => {
                    return (
                        <Tooltip placement="topLeft" title={record.tags} color="#3cba81">
                            <TextTruncate line={3} truncateText="…" text={stripHtml(record.tags)} />
                        </Tooltip>
                    )
                }
            },
            {
                title: 'Price ($)',
                dataIndex: 'regular_price',
                key: 'regular_price',
                width: '100px',
                sorter: true,
                render: (text, record) => {
                    return (
                        <small>
                            <div><b>Regular: </b>{record.regular_price}</div>
                            <div><b>Sale: </b>{record.sale_price}</div>
                        </small>
                    )
                }
            },
            {
                title: 'Store',
                dataIndex: 'store_name',
                sorter: true,
                key: 'store_name',
                width: '100px'
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: true,
                width: '150px',
                render: (text, record) => {
                    return (
                        <div>
                            <div>
                                {moment(record.created_at).format('DD/MM/YYYY HH:mm:ss')}
                            </div>
                        </div>
                    )
                }
            },
            {
                title: 'Cập nhật',
                dataIndex: 'updated_at',
                key: 'updated_at',
                sorter: true,
                width: '150px',
                render: (text, record) => {
                    if (text) {
                        return (
                            <div>
                                <div>
                                    {moment(record.updated_at).format('DD/MM/YYYY HH:mm:ss')}
                                </div>
                            </div>
                        )
                    }
                }
            }
        ];

        if (is_trademark == 1) {
            columns.push(
                {
                    title: 'Trademark keywords',
                    dataIndex: 'matching_keyword',
                    key: 'matching_keyword',
                    width: '150px',
                    render: (text, record) => {
                        if (text) {
                            return (<>{text}</>)
                        }
                    }
                },
            )
        }

        var filters = [
            <BaseSelect
                options={stores || []}
                onChange={(value) => this.onChangeFilter("store_id", value)}
                optionValue="id"
                defaultValue={parseInt(query.store_id) || null}
                optionLabel="name"
                placeholder="Chọn store"
                showSearch
                style={{ width: '150px' }}
            />,
            <TreeSelect
                style={{ width: '150px' }}
                dropdownStyle={{ maxHeight: 450, overflow: 'auto' }}
                treeData={catTree}
                allowClear
                showSearch
                treeNodeFilterProp='title'
                placeholder="Danh mục"
                treeDefaultExpandAll
                defaultValue={parseInt(query.category) || null}
                onChange={(value) => this.onChangeFilter("category", value)}
            />,
            <BaseSelect
                style={{ width: '150px' }}
                disabled={isLoading}
                options={producttype || []}
                optionValue="id"
                optionLabel="name"
                placeholder="Chọn loại sản phẩm"
                showSearch
                allowClear
                onChange={(value) => this.onChangeFilter("product_type_id", value)}
                defaultValue={parseInt(query.product_type_id) || null}
            />,
            <BaseSelect
                options={[
                    { value: 1, label: 'Chưa import' },
                    { value: 2, label: 'Đang import' },
                    { value: 3, label: 'Import lỗi' }
                ]}
                onChange={(value) => this.onChangeFilter("import_status", value)}
                defaultValue={parseInt(query.import_status) || null}
                placeholder="Trạng thái"
                style={{ width: '150px' }}
            />,
            <BaseSelect
                options={[
                    { value: 1, label: 'Đã tạo' },
                    { value: 2, label: 'Chưa tạo' }
                ]}
                onChange={(value) => this.onChangeFilter("description_status", value)}
                defaultValue={parseInt(query.description_status) || null}
                placeholder="Mô tả"
                style={{ width: '120px' }}
            />,
            <BaseSelect
                options={[
                    { value: 'etsy', label: 'Etsy' },
                    { value: 'redbubble', label: 'Redbubble' }
                ]}
                onChange={(value) => this.onChangeFilter("platform", value)}
                defaultValue={query.platform || null}
                placeholder="Platform"
                style={{ width: '120px' }}
            />,
            <BaseSelect
                options={[
                    { value: 'trend', label: 'Trend' },
                    { value: 'normal', label: 'Normal' }
                ]}
                onChange={(value) => this.onChangeFilter("type", value)}
                defaultValue={query.type || null}
                placeholder="Type"
                style={{ width: '120px' }}
            />
        ];
        if (authUser.role_code == 'ADMIN') {
            filters.push(
                <BaseSelect
                    options={users || []}
                    optionValue="user_id"
                    optionLabel="full_name"
                    placeholder="Người tạo"
                    showSearch={false}
                    onSearch={() => this.onSearch()}
                    onScrollEnd={() => this.onScrollEnd()}
                    style={{ width: '150px' }}
                    defaultValue={parseInt(query.created_user_id) || null}
                    onChange={(value) => this.onChangeFilter("created_user_id", value)}
                />
            );
        }

        const table = <DynamicTable
            rowKey="id"
            dataSource={items?.data || []}
            columns={columns}
            isLoading={isLoading}
            pagination={pagination}
            rowSelection={rowSelection}
            moduleKey="products_import"
            onChangeTable={(pagination, sorter) => this.onChangeTable(pagination, sorter)}
        />

        return (
            <div>
                <PageTitle routes={routes} title="Tiến trình import" />
                <div>
                    <TableActionBar
                        isShowAddButton={false}
                        isShowDeleteButton={checkPermission('products_import', 'remove_queue')}
                        onDelete={() => this.onRemove()}
                        isloading_delete={isloading_delete}
                        disabled={!selectedRowKeys.length ? true : false}
                        showSearch={true}
                        showFilter={true}
                        activeFilter={query.category || query.type || query.platform || parseInt(query.store_id) || parseInt(query.category) || parseInt(query.product_type_id) || parseInt(query.import_status) || parseInt(query.options) || parseInt(query.description_status) || null}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword || null}
                        filters={filters}
                    >
                        <Upload
                            beforeUpload={(file) => this.onBeforeUpload(file)}
                            directory
                            showUploadList={false}
                            accept="image/*"
                            customRequest={() => {
                                return false;
                            }}
                            className="table-button"
                        >
                            <Button loading={loadingUpload} disabled={loadingUpload} className="table-button" type="primary" icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                        {
                            checkPermission('products', 'list') ? (
                                <>
                                    <Divider type="vertical" />
                                    <Link to="/products">
                                        <Button className="table-button" type='primary'>D/s sản phẩm</Button>
                                    </Link>
                                </>
                            ) : null
                        }
                        <Divider type="vertical" />
                        <Button className="table-button" onClick={() => this.openManyUpdate()} disabled={!selectedRowKeys.length ? true : false} type="primary">Cập nhật</Button>
                        <Divider type="vertical" />
                        <Button className="table-button" onClick={() => this.setState({ isOpenDescriptionSelect: true })} disabled={!selectedRowKeys.length ? true : false} type="primary">Tạo mô tả</Button>
                        <Divider type="vertical" />
                        {is_trademark == 0 && (
                            <>
                                <Button className="table-button" onClick={() => this.onImport()} disabled={!selectedRowKeys.length ? true : false} type="primary">Import</Button>
                                <Divider type="vertical" />
                            </>
                        )}
                        <Button className="table-button" loading={isLoading} onClick={() => this.reloadData()}>Refresh</Button>
                        {
                            query.import_status == 2 ? (
                                <>
                                    <Divider type="vertical" />
                                    <Button className="table-button" disabled={!selectedRowKeys.length ? true : false} type='primary' onClick={() => this._resetImportStatus()}>Reset</Button>
                                </>
                            ) : null
                        }
                    </TableActionBar>
                    <Row className="mt-2 mb-2" align="middle">
                        {
                            hasSelected ? <span style={{ marginLeft: '10px' }}>Đang chọn {selectedRowKeys.length} bản ghi </span> : ''
                        }
                    </Row>
                </div>
                {
                    importResponseMessage && importResponseType ? (
                        <Alert
                            message={importResponseMessage}
                            type={importResponseType}
                            showIcon
                            className="mb-4"
                            closable
                            onClose={() => this.onSetMessage(null, null)}
                        />
                    ) : null
                }
                <Card size='small' bodyStyle={{ padding: 0 }} className='has-custom-column-table-btn'>
                    <Tabs onChange={(value) => this.onChangeFilter('is_trademark', value)} defaultActiveKey={query.tab} type="card" size="small">
                        <TabPane tab={<span style={{ fontWeight: 600 }}>Sản phẩm</span>} key="0">
                            {table}
                        </TabPane>
                        <TabPane tab={<span style={{ fontWeight: 600 }}>Sản phẩm dính TM</span>} key="1">
                            {table}
                        </TabPane>
                    </Tabs>
                </Card>
                <ImporterViewForm
                    visible={isOpenViewForm}
                    items={images}
                    imageList={imageList}
                    reloadData={() => this.reloadData()}
                    onSetMessage={this.onSetMessage}
                    onCancel={() => this.handleCancel()}
                />
                <DescriptionSelect
                    visible={isOpenDescriptionSelect}
                    ids={selectedRowKeys}
                    reloadData={() => this.reloadData()}
                    onCancel={() => this.setState({ isOpenDescriptionSelect: false, selectedRowKeys: [] })}
                />
                <Drawer
                    visible={openView}
                    title="NỘI DUNG MÔ TẢ"
                    onClose={() => this.setState({ openView: false })}
                    maskClosable={true}
                    width="80%"
                >
                    {
                        openView ?
                            <div className='p-description-detail'>
                                {
                                    currentItem ? (
                                        <>
                                            {ReactHtmlParser(currentItem?.description)}
                                        </>
                                    ) : null
                                }
                            </div>
                            : null
                    }
                </Drawer>
                <Drawer
                    visible={isOpenEdit}
                    title={`Cập nhật #${currentQueueId}`}
                    onClose={() => this.setState({ isOpenEdit: false })}
                    maskClosable={true}
                    footer={false}
                    width={isMobile ? '100%' : '70%'}
                >
                    <div style={{ minHeight: '100px' }}>
                        {
                            LoadingOpenEdit ? (
                                <Spin tip="Loading" size="small">
                                    <div className="content" />
                                </Spin>
                            ) : (
                                <>
                                    <Image.PreviewGroup>
                                        <Radio.Group name="radiogroup" onChange={(e) => this.onChangeThumbnail(e)}>
                                            <Row gutter={20} style={{ height: 'max-content' }} key={1}>
                                                {
                                                    productImagesList.map(item => {
                                                        return (
                                                            <Col lg={6} md={6} sm={6} xs={12} key={item.id} className="mb-3" >
                                                                <div className="list-img-thumbnail" style={{ marginBottom: '6px', width: '100%' }}>
                                                                    {
                                                                        idChecked != item?.id && productImagesList.length > 1 ? (
                                                                            <Tooltip placement="topLeft" title="Click để xóa" color="#3cba81">
                                                                                <DeleteOutlined
                                                                                    className="btn-delete-img2"
                                                                                    onClick={() => this.onDeleteImage(item?.id, item?.queue_id)}
                                                                                />
                                                                            </Tooltip>
                                                                        ) : null
                                                                    }
                                                                    <Radio value={item?.id} queue_id={item?.queue_id}>
                                                                        <Tooltip placement="bottom" title="Chọn làm thumbnail" color="#3cba81">
                                                                            <Image
                                                                                width="100%"
                                                                                src={item?.images}
                                                                                preview={false}
                                                                            />
                                                                        </Tooltip>
                                                                    </Radio>
                                                                </div>
                                                            </Col>
                                                        )
                                                    })
                                                }
                                            </Row>
                                        </Radio.Group>
                                    </Image.PreviewGroup>
                                    <div className='box-cost'>
                                        <Form layout='vertical'>
                                            <Row gutter={16}>
                                                <Col xs={24} md={24}>
                                                    <Form.Item label="Tên sản phẩm">
                                                        {getFieldDecorator("title", {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: "Vui lòng chọn!",
                                                                },
                                                            ],
                                                            initialValue: productList ? productList.title : null
                                                        })(<Input />)}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={16}>
                                                <Col xs={24} md={24}>
                                                    <Form.Item label="Loại sản phẩm">
                                                        {getFieldDecorator("product_type_id", {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: "Vui lòng chọn!",
                                                                },
                                                            ],
                                                            initialValue: productList ? productList.product_type_id : null
                                                        })(
                                                            <BaseSelect
                                                                options={producttype || []}
                                                                optionValue="id"
                                                                optionLabel="name"
                                                                placeholder="Chọn loại sản phẩm"
                                                                showSearch
                                                                allowClear
                                                            />
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={16}>
                                                <Col xs={24} md={8}>
                                                    <Form.Item label='Primary categories'>
                                                        {getFieldDecorator("main_categories_id", {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: "Vui lòng chọn!",
                                                                },
                                                            ],
                                                            initialValue: productList ? productList.main_categories_id : null
                                                        })(
                                                            <TreeSelect
                                                                style={{ width: '100%' }}
                                                                dropdownStyle={{ maxHeight: 450, overflow: 'auto' }}
                                                                treeData={catTree}
                                                                loading={loadingCategory}
                                                                disabled={loadingCategory}
                                                                multiple={false}
                                                                showSearch
                                                                treeNodeFilterProp='title'
                                                                allowClear
                                                                placeholder="Chọn một categories"
                                                                treeDefaultExpandAll
                                                            />
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={16}>
                                                    <Form.Item label='Categories'>
                                                        {getFieldDecorator("categories", {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: "Vui lòng chọn!",
                                                                },
                                                            ],
                                                            initialValue: productList ? productList.currentCategoryId : []
                                                        })(
                                                            <TreeSelect
                                                                style={{ width: '100%' }}
                                                                dropdownStyle={{ maxHeight: 450, overflow: 'auto' }}
                                                                treeData={catTree}
                                                                loading={loadingCategory}
                                                                disabled={loadingCategory}
                                                                multiple={true}
                                                                showSearch
                                                                treeNodeFilterProp='title'
                                                                allowClear
                                                                placeholder="Chọn một hoặc nhiều categories"
                                                                treeDefaultExpandAll
                                                                onChange={this.handleCategoryChange}
                                                            />
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            {/* <Row gutter={16}>
                                                <Col xs={24} md={24}>
                                                    <Form.Item label='Tags'>
                                                        {getFieldDecorator("tags", {
                                                            rules: [
                                                                {
                                                                    required: false,
                                                                    message: "Vui lòng chọn!",
                                                                },
                                                            ],
                                                            initialValue: productList ? productList.currentTagsId : null
                                                        })(
                                                            <Select
                                                                disabled={loadingTags}
                                                                loading={loadingTags}
                                                                mode="multiple"
                                                                style={{ width: '100%' }}
                                                                placeholder="Chọn một hoặc nhiều tags"
                                                                onChange={this.handleTagsChange}
                                                                optionLabelProp="label"
                                                                showSearch={true}
                                                                allowClear={true}
                                                            >
                                                                {
                                                                    tags && tags.length ? (
                                                                        <>
                                                                            {
                                                                                tags.map((item) => {
                                                                                    return (
                                                                                        <Option key={item.id} value={item.id} label={item.name}>{item.name}</Option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </>
                                                                    ) : null
                                                                }
                                                            </Select>
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                            </Row> */}
                                            <div style={{ textAlign: 'right' }} className="mt-3">
                                                <Button loading={isloadingUpdateItem} key="submit" type="primary" onClick={(e) => this.onUpdateItem(e)}>
                                                    Cập nhật
                                                </Button>
                                            </div>
                                        </Form>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </Drawer>
                <Modal
                    visible={isOpenManyUpdate}
                    title="Cập nhật hàng loạt"
                    onCancel={() => this.handleCancel()}
                    maskClosable={true}
                    footer={false}
                    width={isMobile ? '100%' : '40%'}
                >
                    <div style={{ minHeight: '100px' }}>
                        <Form layout='vertical'>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Loại sản phẩm">
                                        {getFieldDecorator("product_type", {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: null
                                        })(
                                            <BaseSelect
                                                options={producttype || []}
                                                optionValue="id"
                                                optionLabel="name"
                                                placeholder="Chọn loại sản phẩm"
                                                showSearch
                                                allowClear
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={8}>
                                    <Form.Item label='Primary categories'>
                                        {getFieldDecorator("main_categories", {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: null
                                        })(
                                            <TreeSelect
                                                style={{ width: '100%' }}
                                                dropdownStyle={{ maxHeight: 450, overflow: 'auto' }}
                                                treeData={catTree}
                                                loading={loadingCategory}
                                                disabled={loadingCategory}
                                                multiple={false}
                                                showSearch
                                                treeNodeFilterProp='title'
                                                allowClear
                                                placeholder="Chọn một categories"
                                                treeDefaultExpandAll
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={16}>
                                    <Form.Item label='Categories'>
                                        {getFieldDecorator("categories2", {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: []
                                        })(
                                            <TreeSelect
                                                style={{ width: '100%' }}
                                                dropdownStyle={{ maxHeight: 450, overflow: 'auto' }}
                                                treeData={catTree}
                                                loading={loadingCategory}
                                                disabled={loadingCategory}
                                                multiple={true}
                                                showSearch
                                                treeNodeFilterProp='title'
                                                allowClear
                                                placeholder="Chọn một hoặc nhiều categories"
                                                treeDefaultExpandAll
                                                onChange={this.handleCategoryChange}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div style={{ textAlign: 'right' }} className="mt-3">
                                <Button loading={isloadingUpdateItem} key="submit" type="primary" onClick={(e) => this.onManyUpdateItem(e)}>
                                    Cập nhật
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Modal>
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        items: state.ProductsImportReducer.items,
        pagination: state.ProductsImportReducer.pagination,
        authUser: state.auth.authUser,
        stores: state.StoreReducer.store_by_field_list,
        categories: state.ProductsCategories.categories,
        producttype: state.ProductType.producttype,
        users: state.config.users
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAllQueue: (filter) => dispatch(getAllQueue(filter)),
        removeQueue: (ids) => dispatch(removeQueue(ids)),
        getAllCategories: filter => dispatch(getAllCategories(filter)),
        getProductTypeByActive: (filter) => dispatch(getProductTypeByActive(filter)),
        getOne: (id) => dispatch(getOne(id)),
        importNow: (data) => dispatch(importNow(data)),
        setThumbnail: (data) => dispatch(setThumbnail(data)),
        removeQueueImage: (id, queue_id) => dispatch(removeQueueImage(id, queue_id)),
        getAllTagsInProductForm: filter => dispatch(getAllTagsInProductForm(filter)),
        updateRecordProduct: (id, data) => dispatch(updateRecordProduct(id, data)),
        manyUpdate: (data) => dispatch(manyUpdate(data)),
        getStoreByField: (filter) => dispatch(getStoreByField(filter)),
        resetImportStatus: (ids) => dispatch(resetImportStatus(ids))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "ProcessList" })(ProcessList));
